import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetWithBuildingModel } from "./baseAssetWithBuildingModel";
export default class StoreyAsset extends BaseAssetWithBuildingModel {
  static name = "StoreyAsset";
  static entity = "storeyAssets";
  static aosModel = () => AosVuexOrmModel["AosStorey"];

  static fields() {
    return {
      ...super.fields(),

      code: this.string(""),

      // Reception and evacuation capacity charcateristics
      nbStoreySanitary: this.number(null).nullable(),
      nbStoreySanitarySource: this.attr(""),
      nbStoreyWomenSanitary: this.number(null).nullable(),
      nbStoreyWomenSanitarySource: this.attr(""),
      nbStoreyMenSanitary: this.number(null).nullable(),
      nbStoreyMenSanitarySource: this.attr(""),
      maximumWorkforceLinkedToSanitaryNumber: this.number(null).nullable(),
      maximumWorkforceLinkedToSanitaryNumberSource: this.attr(""),
      nbStoreyEmergencyExits: this.number(null).nullable(),
      nbStoreyEmergencyExitsSource: this.attr(""),
      nbStoreyPassageUnits: this.number(null).nullable(),
      nbStoreyPassageUnitsSource: this.attr(""),
      cumulativeWidthOfPassage: this.number(null).nullable(),
      cumulativeWidthOfPassageSource: this.attr(""),
      maximumWorkforceRelatedToEmergencyExits: this.number(null).nullable(),
      maximumWorkforceRelatedToEmergencyExitsSource: this.attr(""),
      ventilationFreshAirFlows: this.number(null).nullable(),
      ventilationFreshAirFlowsSource: this.attr(""),
      storeyVolume: this.number(null).nullable(),
      storeyVolumeSource: this.attr(""),
      openingsSurface: this.number(null).nullable(),
      openingsSurfaceSource: this.attr(""),
      maximumWorkforceRelatedToVentilation: this.number(null).nullable(),
      maximumWorkforceRelatedToVentilationSource: this.attr(""),
      averageOccupancyRatio: this.number(null).nullable(),
      averageOccupancyRatioSource: this.attr(""),
      bearingCapacityOfTheFloor: this.number(null).nullable(),
      bearingCapacityOfTheFloorSource: this.attr(""),
      bearingCapacityOftheSlab: this.number(null).nullable(),
      bearingCapacityOftheSlabSource: this.number(null).nullable(),
    };
  }
}
