import { BaseAdminGroupModel } from "./baseModels";

export default class DifferabilityGroup extends BaseAdminGroupModel {
  static name = "DifferabilityGroup";

  static entity = "differabilityGroups";

  static fields() {
    return {
      ...super.fields(),
      differabilitiesList: this.attr([]),
    };
  }

  get itemsList() {
    return this.differabilitiesList;
  }
}
