import { Model } from "@vuex-orm/core";
import { ReferencialNode } from "@socotec.io/socio-vue-components";
export class BaseModel extends Model {
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      updatedAt: this.string(""),
      createdAt: this.string(""),
      displayed: this.boolean(true),
    };
  }

  static async insertOrUpdate(payload, skipCache = false) {
    const data = await super.insertOrUpdate(payload, skipCache);
    this.commit((state) => {
      state.isInserted = true;
    });
    return data;
  }

  static async insert(payload, skipCache = false) {
    const data = await super.insert(payload, skipCache);
    this.commit((state) => {
      state.isInserted = true;
    });
    return data;
  }
}

export class BaseMissionModel extends Model {
  static primaryKey = "code";

  static fields() {
    return {
      code: this.attr(""),
      label: this.attr(""),
      description: this.attr(""),
    };
  }
}

export class BaseAdminGroupModel extends BaseModel {
  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(""),
      label: this.attr(""),
      ordering: this.attr(999),
      isArchived: this.boolean(false),
    };
  }
}

export class BaseReferencialModel extends ReferencialNode {
  static state() {
    return {
      aggregatesMap: {},
    };
  }

  static fields() {
    return {
      ...super.fields(),
      referencialName: this.string(""),
      fullLabel: this.string(""),
      breadcrumbs: this.attr([]),
    };
  }

  static setSmallestQuotationValue(nodeId, value) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].smallestQuotationValue = value;
    });
  }

  static setRelatedReferencialTypeCount(nodeId, value) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].relatedReferencialTypeCount = value;
    });
  }

  static decrementReferencialTypeCount(nodeId) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].relatedReferencialTypeCount -= 1;
    });
  }

  static clearAggregateState() {
    this.commit((state) => {
      state.aggregatesMap = {};
    });
  }

  static getAggregatesByNodeUuid(nodeId) {
    // kind of a mapState for models
    const modelStates =
      this.store().state[this.database().namespace][this.entity];
    return modelStates.aggregatesMap[nodeId];
  }

  makeFullLabel() {
    return `${this.path} ${this.label}`;
  }

  makeNodeBreadcrumbs() {
    const pathAsArr = this.path.split(".");
    if (!pathAsArr.length) return [];

    pathAsArr.pop();
    const breadcrumbs = [this.makeFullLabel()];
    while (pathAsArr.length) {
      const parentItemQuery = this.constructor
        .query()
        .where((node) => node.path === pathAsArr.join("."));
      if (!parentItemQuery.exists()) break;

      const parentItem = parentItemQuery.first();
      breadcrumbs.push(parentItem.makeFullLabel());
      pathAsArr.pop();
    }

    return breadcrumbs;
  }
}
