import { utils } from "@socotec.io/socio-aos-component";
import { BaseModel } from "./baseModels";

export default class AosObservable extends BaseModel {
  static name = "AosObservable";
  static entity = "aosObservables";

  static rxdbIndexes = ["aosItem"];

  static fields() {
    return {
      ...super.fields(),
      assetType: this.string(""),

      // Foreign keys
      assetUuid: this.string(""),
      aosItem: this.string(""),
      createdOrUpdatedOffline: this.boolean(false),

      // aggregates
      assetModel: this.string(""),
      assetData: this.morphTo("assetUuid", "assetModel"),
    };
  }

  get aosItemData() {
    return utils.aosConst.AOS_ITEMS[this.assetType.toLowerCase()]
      .aosModelClass()
      .find(this.aosItem);
  }
}
