import { BaseModel } from "./baseModels";
import ProjectConfigAnalyticalAxis from "./ProjectConfigAnalyticalAxis";

export const ANALYTICAL_AXIS_CODES = {
  // This is a copy of AnalyticalAxisEnum from Amos Back
  DOC_REVIEW: "DOC_REVIEW",
  FIRE_SAFETY: "FIRE_SAFETY",
  ACCESSIBILITY: "ACCESSIBILITY",
  DILAPIDATION: "DILAPIDATION",
  ENERGY: "ENERGY",
  TAXONOMY_CONSTRUCTION: "TAXONOMY_CONSTRUCTION",
  TAXONOMY_EXPLOITATION: "TAXONOMY_EXPLOITATION",
  TAXONOMY_RENOVATION: "TAXONOMY_RENOVATION",
  ADAPTABILITY: "ADAPTABILITY",
  FLEXIBILITY: "FLEXIBILITY",
  DURABILITY: "DURABILITY",
  RECYCLE: "RECYCLE",
  LOW_CARBONE: "LOW_CARBONE",
  WIRED_CONSTRUCTION: "WIRED_CONSTRUCTION",
  WIRED_EXPLOITATION: "WIRED_EXPLOITATION",
  SI_DIAG: "SI_DIAG",
  AH_DIAG: "AH_DIAG",
  IRVE: "IRVE",
  CONSERVATION: "CONSERVATION",
  RUSTING: "RUSTING",
  TECHNICAL_ADVICE: "TECHNICAL_ADVICE",
  FUNCTIONAL_REQUIREMENTS: "FUNCTIONAL_REQUIREMENTS",
};

export default class AnalyticalAxis extends BaseModel {
  static name = "AnalyticalAxis";

  static entity = "analyticalAxes";

  static state() {
    return {
      codeToUuidMap: {},
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      label: this.string(""),
      shortLabel: this.attr(""),
      isArchived: this.attr(false),
      code: this.string(""),
      ordering: this.number(0),
      description: this.attr(""),
      missionObject: this.attr(""),
      missionObjective: this.attr(""),
      referenceText: this.attr(""),
      projectConfigAnalyticalAxes: this.hasMany(
        ProjectConfigAnalyticalAxis,
        "analyticalAxis"
      ),
    };
  }

  static afterCreate(model) {
    this.commit((state) => {
      state.codeToUuidMap[model.code] = model.uuid;
    });
  }
}
