import { BaseModel } from "./baseModels";

export default class ActionType extends BaseModel {
  static name = "ActionType";

  static entity = "actionTypes";

  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      label: this.attr(""),
      isArchived: this.attr(false),
      description: this.attr(""),
      ordering: this.attr(0),
      uniqueCode: this.attr(""),
    };
  }
}
