import SiteAsset from "./SiteAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { TagThrough } from "./AssetTag";
import { BaseAssetModel } from "./baseAssetModel";
import BuildingCharacteristic from "./BuildingCharacteristic";

export class BuildingTag extends TagThrough {
  static name = "BuildingTag";
  static entity = "buildingTags";
  static relatedModel = () => BuildingAsset;
}

export default class BuildingAsset extends BaseAssetModel {
  static primaryKey = "uuid";
  static name = "BuildingAsset";
  static entity = "buildingAssets";
  static aosModel = () => AosVuexOrmModel["AosBuilding"];

  static fields() {
    return {
      ...super.fields(),
      isCaseAsset: this.boolean(),
      site: this.string(""), // foreign key
      tags: this.attr([]),
      code: this.string(""),
      siteData: this.belongsTo(SiteAsset, "site"),
      tagsData: this.hasManyBy(BuildingTag, "tags"),
      characteristics: this.hasOne(BuildingCharacteristic, "uuid", "uuid"),
    };
  }
}
