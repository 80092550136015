import { BaseAssetWithBuildingModel } from "./baseAssetWithBuildingModel";
import BuildingAsset from "./BuildingAsset";
import SiteAsset from "./SiteAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

export default class ComponentAsset extends BaseAssetWithBuildingModel {
  static name = "ComponentAsset";
  static entity = "componentAssets";
  static aosModel = () => AosVuexOrmModel["AosComponent"];

  static fields() {
    return {
      ...super.fields(),
      site: this.string(""), // foreign key
      siteData: this.attr({}),
      code: this.string(""),
      observationComment: this.string(""),
    };
  }

  static afterCreate(model) {
    const pathMap = this.store().state.asset.pathToAssetIdMap;
    const [siteCode, buildingCode] = model.path.split(".");

    if (buildingCode === "0") {
      const siteId = pathMap[siteCode];
      if (!siteId) return;
      model.site = siteId;
      model.siteData = SiteAsset.find(siteId);
    }

    const buildingPath = `${siteCode}.${buildingCode}`;
    const buildingId = pathMap[buildingPath];
    if (!buildingId) return;

    model.building = buildingId;
    model.buildingData = BuildingAsset.find(buildingId);
  }
}
