import { BaseAdminGroupModel } from "./baseModels";
import Quotation from "./Quotation";
import QuotationGroupQuotationPivot from "./QuotationGroupQuotationPivot";

export default class QuotationGroup extends BaseAdminGroupModel {
  static name = "QuotationGroup";

  static entity = "quotationGroups";

  static fields() {
    return {
      ...super.fields(),
      quotations: this.belongsToMany(
        Quotation,
        QuotationGroupQuotationPivot,
        "quotationGroupUuid",
        "quotationUuid"
      ),
      quotationsList: this.attr([]),
      groupTypesList: this.attr([]),
    };
  }
}
