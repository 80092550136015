import store from "@/store";
import { showErrorNotification } from "@/composables/useNotifications";
import { APP_NAMES, MISSION_CODES_TO_APP } from "@/utils/const";
import db from "@/rxdb/utils";
import { ProjectConfig } from "@/models";

const isAllowed = async (projectUuid) => {
  let currentUser = store.getters["user/getCurrentUser"];
  if (!currentUser.isAllowedRead(projectUuid)) {
    throw new Error("You are not allowed to access this project");
  }
};

const commitProjectInfos = (project) => {
  const appName = MISSION_CODES_TO_APP[project.mission];
  const currentApp = APP_NAMES[appName] ?? APP_NAMES.AMOS_REAL_ESTATE;
  store.commit("project/SET_CURRENT_PROJECT", project.uuid);
  store.commit("project/SET_CURRENT_APP", currentApp);
};

const initializeProject = async (projectUuid) => {
  const project = await store.dispatch("project/retrieveProjectData", {
    projectUuid,
  });
  commitProjectInfos(project);
  return project;
};

const initializeOfflineProject = async (projectUuid) => {
  const [project] = await db.value.project
    .find()
    .where("uuid")
    .eq(projectUuid)
    .exec();
  const projectConfig = await db.value.project_config
    .find()
    .where("project")
    .eq(projectUuid)
    .exec();
  await ProjectConfig.insert({
    data: JSON.parse(JSON.stringify(projectConfig)),
  });
  store.commit("project/SET_IS_PICASSO_VISIT", project.mission === "HAIA");
  commitProjectInfos(project);
};

export default async (to, from, next) => {
  store.commit("SET_APP_IS_LOADING", true);
  const { projectUuid } = to.params;
  await isAllowed(projectUuid);

  if (!navigator.onLine) {
    await initializeOfflineProject(projectUuid);
    next();
  } else {
    try {
      const project = await initializeProject(projectUuid);
      const isProjectConfigured =
        !!project.projectConfigData?.submissions.length;

      if (isProjectConfigured || to.name === "project-config") {
        next();
      } else {
        next({ name: "project-config", params: to.params });
      }
    } catch (err) {
      console.error(err);
      await showErrorNotification("Access denied");
      if (!from.name) next({ name: "cases" });
      return;
    }
  }
  store.commit("SET_APP_IS_LOADING", false);
};
