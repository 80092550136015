import { BaseAdminGroupModel } from "./baseModels";

export default class RiskAssessmentGroup extends BaseAdminGroupModel {
  static name = "RiskAssessmentGroup";

  static entity = "riskAssessmentGroups";

  static fields() {
    return {
      ...super.fields(),
      riskAssessmentsList: this.attr([]),
    };
  }

  get itemsList() {
    return this.riskAssessmentsList;
  }
}
