import { BaseModel } from "./baseModels";
import { Project, User } from "@/models";

export default class CacheVisitSettings extends BaseModel {
  static name = "CacheVisitSettings";

  static entity = "cacheVisitSettings";

  static primaryKey = "project";

  static fields() {
    return {
      ...super.fields(),
      createdAt: this.string("", () => new Date().toISOString()),
      project: this.string(""),
      projectData: this.belongsTo(Project, "project"),
      user: this.string(""),
      userData: this.belongsTo(User, "user"),
      status: this.string(""),
    };
  }
}
