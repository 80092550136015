import store from "@/store";
import { assetTypesByRoute, ASSET_ITEMS } from "@/utils/const";

export default (to, from, next) => {
  const aosState = store.state.aos;
  const assetTypes =
    assetTypesByRoute[to.query.redirect] ?? Object.keys(ASSET_ITEMS);

  for (const assetItemType of [...assetTypes].reverse()) {
    const selectionState = aosState[assetItemType][`${assetItemType}sSelected`];
    if (!selectionState.length) {
      continue;
    }
    if (selectionState.length > 1) {
      return next();
    }
    const [aosItemUuid] = selectionState;
    const assetItemUuid = ASSET_ITEMS[assetItemType]
      .modelClass()
      .query()
      .where("aosItem", aosItemUuid)
      .first().uuid;
    return next({
      name: to.query.redirect,
      params: {
        assetItemType,
        assetItemUuid,
      },
    });
  }
  next();
};
