import { ROLES } from "@/utils/const";
import { User as BaseUser, Role } from "@socotec.io/socio-vue-components";

export default class User extends BaseUser {
  static name = "User";
  static entity = "users";
  static retrieveAction = "user/retrieveByUuid";
  static listAction = "user/fetchManyWithMetadata";

  static fields() {
    return {
      ...super.fields(),
      // INFO - L.G. - 10/10/2022 - All of the user managed project
      // To look over when we will have a lot of projects
      managedProjects: this.attr([]),
      roles: this.attr([]),
    };
  }

  get isExternal() {
    return this.groups.includes("ExternalUser");
  }

  get isExternalWithAppAccess() {
    // FIXME: AS - 17/08/2023 - Investigate on why socotecIoGroups (AMOS BACK) aren't synced with UM / Keycloak
    // const isInGroupApp = this.groups.includes("AMOS");
    const isInGroupApp = true;
    return this.isExternal && isInGroupApp;
  }

  getProjectRole(projectUuid) {
    return this.roles.filter((role) => role.project === projectUuid)[0]?.role;
  }

  updateProjectRole(projectUuid, projectRoleId) {
    if (!projectRoleId) {
      this.roles = this.roles.filter((role) => role.project !== projectUuid);
      return;
    }
    const index = this.roles.findIndex((role) => role.project === projectUuid);
    const projectRole = Role.find(projectRoleId)?.name;
    if (index !== -1) {
      this.roles[index].role = projectRole;
    } else {
      this.roles.push({ project: projectUuid, role: projectRole });
    }
  }

  isPilotOrProjectManager(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return [ROLES.PILOT, ROLES.PROJECT_MANAGER].includes(
      this.getProjectRole(projectUuid)
    );
  }

  isContributor(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return !!this.getProjectRole(projectUuid);
  }

  isInternalContributor(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return !this.isExternal && this.isContributor(projectUuid);
  }

  isManager(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return this.managedProjects.includes(projectUuid);
  }

  isCustomer(projectUuid) {
    return this.isExternalWithAppAccess && this.isContributor(projectUuid);
  }

  isAllowedRead(projectUuid) {
    // Default is to allow read access to all Contributors and related Agency Managers
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return (
      this.isManager(projectUuid) ||
      this.isInternalContributor(projectUuid) ||
      this.isCustomer(projectUuid) ||
      this.isAllowed(projectUuid) ||
      this.isExternalWithAppAccess
    );
  }

  isPrivileged() {
    return this.isDev() || this.isTechnicalDirection();
  }

  isAllowed(projectUuid) {
    // Default is to allow access to Devs, Pilots and Project Managers
    return this.isPrivileged() || this.isPilotOrProjectManager(projectUuid);
  }
  isAtLeastContributor(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return (
      this.isInternalContributor(projectUuid) || this.isAllowed(projectUuid)
    );
  }

  isCT(projectUuid) {
    if (!projectUuid) {
      projectUuid = this.$store().getters["project/getProjectUuid"];
    }
    return (
      this.isInternalContributor(projectUuid) &&
      [ROLES.TECHNICAL_CONTROLLER, "Contrôleur technique"].includes(
        this.getProjectRole(projectUuid)
      )
    );
  }
}
