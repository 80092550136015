import { BaseModel } from "./baseModels";
import Observation from "./Observation";
import ObjectTypeStatement from "./ObjectTypeStatement";
import QuotationValue from "./QuotationValue";
import StatementType from "./StatementType";
import { hydrateDocumentIdsToUrls } from "@/utils/observation";
import { isEqual } from "lodash";

export default class ObservationStatement extends BaseModel {
  static name = "ObservationStatement";
  static entity = "observationStatements";

  static editableFields = ["justification", "documentsList"];

  static hasDiffs(a, b) {
    if (!a || !b) return false;
    return this.editableFields.some((field) => !isEqual(a[field], b[field]));
  }

  static fields() {
    return {
      ...super.fields(),
      justification: this.string(""),
      observationData: this.belongsTo(Observation, "observation"),
      objectTypeStatementData: this.belongsTo(
        ObjectTypeStatement,
        "objectTypeStatement"
      ),

      // aggregates
      statementTypeData: this.belongsTo(StatementType, "statementType"),
      quotationValueData: this.belongsTo(QuotationValue, "quotationValue"),
      statementType: this.string(""),
      quotationValue: this.attr(""),
      amosDocumentsUrlsList: this.attr([]),
      createdByFirstname: this.string(""),
      modifiedByFirstname: this.string(""),
      createdByLastname: this.string(""),
      modifiedByLastname: this.string(""),
      // foreign keys
      observation: this.string(""),
      objectTypeStatement: this.string(""),
      documentsList: this.attr([]),

      createdBy: this.string(""),
      modifiedBy: this.string(""),

      // draftStatementWorkflow
      isDraft: this.boolean(false),
    };
  }

  static beforeCreate(model) {
    hydrateDocumentIdsToUrls(model);
  }
}
