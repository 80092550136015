import { BaseModel } from "./baseModels";
import store from "@/store";
import AosObservable from "./AosObservable";
import RiskAssessment from "./RiskAssessment";
import BIMDataPin from "./BIMDataPin";
import Action from "./Action";
import {
  hydrateDocumentIdsToUrls,
  getSortedDistinctStatements,
} from "@/utils/observation";
import { isEqual } from "lodash";
import { makePerimeterFromAosObservables } from "@/utils/perimeterRepr";
import ObservationStatement from "./ObservationStatement";

let _documentsUrlAndNames = [];

export default class Observation extends BaseModel {
  static name = "Observation";
  static entity = "observations";
  static listAction = "observation/fetchObservations";

  static editableFields = [
    "ctComment",
    "refCode",
    "isValidated",
    "perimeter",
    "riskAssessment",
    "smallestQuotationValue",
    "actionsCount",
    "aosObservablesList",
    "functionalRequirementsList",
    "amosDocumentsList",
    "typologyTypesList",
    "dispositionTypesList",
    "ctObservation",
    "metadata",
  ];

  static hasDiffs(a, b) {
    if (!a || !b) return false;
    return this.editableFields.some((field) => !isEqual(a[field], b[field]));
  }

  static fields() {
    return {
      ...super.fields(),
      createdBy: this.string(""),
      modifiedBy: this.string(""),
      origin: this.string(""),
      metadata: this.attr({}),
      ctComment: this.string(""),
      refCode: this.string(""),
      isValidated: this.boolean(false),
      perimeter: this.attr(null),
      isInSentReport: this.boolean(false),

      // foreign keys
      aosObservablesList: this.attr([]),
      amosDocumentsList: this.attr([]),
      riskAssessment: this.attr(null),
      ctObservation: this.attr(),

      // Orm relation
      riskAssessmentData: this.belongsTo(RiskAssessment, "riskAssessment"),
      pin: this.hasOne(BIMDataPin, "observation"),

      // aggregates
      actionsCount: this.number(0),
      smallestQuotationValue: this.number(-1),
      smallestQuotationStatement: this.string(""),
      analyticalAxis: this.string(""),
      statementsCount: this.number(0),
      createdByEmail: this.string(""),
      modifiedByEmail: this.string(""),
      createdByFirstname: this.string(""),
      modifiedByFirstname: this.string(""),
      createdByLastname: this.string(""),
      modifiedByLastname: this.string(""),
      amosDocumentsUrlsList: this.attr([]),

      // reverse FK to referencial node models
      dispositionTypesList: this.attr([]),
      typologyTypesList: this.attr([]),

      // draftStatementWorkflow
      isDraft: this.boolean(false),

      functionalRequirementsList: this.attr([]),
    };
  }

  static beforeCreate(model) {
    hydrateDocumentIdsToUrls(model);
  }

  makePerimeter() {
    const { assets, pathsMapping } =
      this.$store().getters["asset/getAllAssets"];
    return makePerimeterFromAosObservables({
      aosObservablesList: this.aosObservablesList,
      assets,
      pathsMapping,
    });
  }

  static afterDelete(model) {
    Action.query()
      .where((action) => action.observationsList.includes(model.uuid))
      .get()
      .forEach((action) => {
        const updatedM2M = action.observationsList.filter(
          (obsUuid) => obsUuid !== model.uuid
        );

        action.observationsList = updatedM2M;
        action.$save();
      });
  }

  async setDocumentsData() {
    _documentsUrlAndNames = await Promise.all(
      this.amosDocumentsList.map(async (docoumentUuid) =>
        store.dispatch("document/getDocumentUrlAndName", docoumentUuid)
      )
    );
  }

  get relatedAosItems() {
    return AosObservable.findIn(this.aosObservablesList).map(
      (pObs) => pObs.aosItemData
    );
  }

  get relatedObservableItems() {
    return AosObservable.findIn(this.aosObservablesList);
  }

  get documentsData() {
    return _documentsUrlAndNames;
  }

  get ctObservationStatementCount() {
    if (!this.ctObservation) return 0;
    const observationIds = [this.uuid, this.ctObservation.uuid];

    const statements = getSortedDistinctStatements({
      statements: ObservationStatement.query()
        .where((item) => observationIds.includes(item.observation))
        .get(),
    });

    return statements.length;
  }
}
