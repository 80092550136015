import { BaseModel } from "./baseModels";

export default class Periodicity extends BaseModel {
  static name = "Periodicity";
  static entity = "periodicities";
  static primaryKey = "uuid";
  static retrieveAction = "action/retrievePeriodicity";

  static fields() {
    return {
      ...super.fields(),
      start: this.attr(),
      number: this.attr(),
      durationType: this.attr(""),
      ending: this.attr(""),
      startDateFormat: this.attr(),
      endingDateFormat: this.attr(),
    };
  }

  get periodicityString() {
    return `${this.number} ${this.durationType}`;
  }
}
