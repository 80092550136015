import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  // INFO - MS - 07/06/23 - We need to identify when the service-worker is installed the first time,
  // to force reload the page when switching offline (see src/components/Amos.vue, switchOfflineMode()).
  // Another solution to explore is https://developer.chrome.com/docs/workbox/modules/workbox-recipes#warm_strategy_cache
  wb.addEventListener("installed", (event) => {
    if (!event.isUpdate) {
      console.log("sw installed first time");
      localStorage.setItem("service-worker-first-installed", "true");
    }
  });

  wb.register();
} else {
  wb = null;
}
export default wb;
