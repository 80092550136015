import { BaseAdminModel } from "./BaseAdminModel";

export default class ActionStatus extends BaseAdminModel {
  static name = "ActionStatus";

  static entity = "actionStatuses";

  static fields() {
    return {
      ...super.fields(),
      shortLabel: this.attr(""),
    };
  }
}
