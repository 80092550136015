import { BaseAdminGroupModel } from "./baseModels";

export default class SourcePricingGroup extends BaseAdminGroupModel {
  static name = "SourcePricingGroup";

  static entity = "sourcePricingGroups";

  static fields() {
    return {
      ...super.fields(),
      sourcePricingsList: this.attr([]),
    };
  }

  get itemsList() {
    return this.sourcePricingsList;
  }
}
