import beforeEnterSelection from "@/router/middleware/selectionMiddleware";
import ReportsChildren from "./children/reports-children";
import GedChildren from "./children/ged-children";
import AosItemChildren from "./children/aos-item-children";
import ConfigChildren from "./children/config-children";
import ArbitrationChildren from "./children/arbitration-children";
import actionMiddleware from "@/router/middleware/actionMiddleware";
import arbitrationMiddleware from "@/router/middleware/arbitrationMiddleware";
import { amosRootPermissions } from "@/utils/amosPermissions";

const Config = () =>
  import(
    /* webpackChunkName: "Config" */ "@/components/amos/visits/visit/Config"
  );

const Observations = () =>
  import(
    /* webpackChunkName: "Observations" */
    "@/components/amos/visits/visit/Observations"
  );

const Actions = () =>
  import(
    /* webpackChunkName: "Actions" */ "@/components/amos/visits/visit/Actions"
  );

const Arbitration = () =>
  import(
    /* webpackChunkName: "Arbitration" */ "@/components/amos/visits/visit/Arbitration"
  );

const Ged = () =>
  import(/* webpackChunkName: "Ged" */ "@/components/amos/visits/visit/Ged");

const Reports = () =>
  import(
    /* webpackChunkName: "Reports" */ "@/components/amos/visits/visit/Reports"
  );

const Contributors = () =>
  import(
    /* webpackChunkName: "Contributors" */ "@/components/amos/visits/visit/Contributors"
  );

const AosItemSelection = () =>
  import(
    /* webpackChunkName: "Selection" */
    "@/components/amos/visits/visit/AosItemSelection"
  );

const AosItemRoutes = () =>
  import(
    /* webpackChunkName: "Selection" */
    "@/components/amos/visits/visit/AosItemRoutes"
  );

const DocumentReview = () =>
  import(
    /* webpackChunkName: "DocumentReview" */ "@/components/amos/visits/visit/aos-item-routes/DocumentReview"
  );

const Dispositions = () =>
  import(
    /* webpackChunkName: "Dispositions" */ "@/components/amos/visits/visit/aos-item-routes/Dispositions"
  );

const TechnicalInspectionObservations = () =>
  import(
    /* webpackChunkName: "TechnicalInspectionObservations" */ "@/components/amos/visits/visit/aos-item-routes/TechnicalInspectionObservations"
  );

const Dashboard = () =>
  import(
    /* webpackChunkName: "Dispositions" */ "@/components/amos/visits/visit/aos-item-routes/Dashboard"
  );

export default [
  {
    path: "config",
    component: Config,
    children: ConfigChildren,
  },
  {
    path: "selection",
    name: "aos-item-selection",
    component: AosItemSelection,
    beforeEnter: beforeEnterSelection,
    meta: {
      permissions: amosRootPermissions["aos-item-selection"],
    },
  },
  {
    path: ":assetItemType/:assetItemUuid",
    name: "aos-item-route",
    component: AosItemRoutes,
    children: AosItemChildren,
  },
  {
    path: "document-review",
    name: "document-review",
    component: DocumentReview,
    meta: {
      permissions: amosRootPermissions["document-review"],
    },
  },
  {
    path: "dispositions",
    name: "dispositions",
    component: Dispositions,
    meta: {
      permissions: amosRootPermissions.dispositions,
    },
  },
  {
    path: "diagnostic",
    name: "diagnostic",
    component: TechnicalInspectionObservations,
    meta: {
      permissions: amosRootPermissions.diagnostic,
    },
  },
  {
    path: "observations",
    name: "observations",
    component: Observations,
    meta: {
      permissions: amosRootPermissions.observations,
    },
  },
  {
    path: "actions",
    name: "actions",
    component: Actions,
    beforeEnter: actionMiddleware,
  },
  {
    path: "arbitration",
    component: Arbitration,
    children: ArbitrationChildren,
    beforeEnter: arbitrationMiddleware,
  },
  {
    path: "ged",
    children: GedChildren,
    component: Ged,
    meta: {
      permissions: amosRootPermissions.ged,
    },
  },
  {
    path: "reports",
    children: ReportsChildren,
    component: Reports,
  },
  {
    path: "contributors",
    name: "project-contributors",
    component: Contributors,
    meta: {
      permissions: amosRootPermissions["project-contributors"],
    },
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard,
  },
];
