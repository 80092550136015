import { Document as SocioDocument } from "@socotec.io/socio-vue-components";
import TypologyType from "./TypologyType";
import { Relation } from "@/models/Relation";

export default class Document extends SocioDocument {
  static name = "Document";

  static retrieveAction = "document/retrieveDocument";
  static listAction = "document/fetchDocuments";
  static fields() {
    return {
      ...super.fields(),
      // UUID of Document Service
      documentUuid: this.string(""),
      createdBy: this.string(""),
      datetimeFormat: this.attr(),
      perimeter: this.attr(null),
      relations: this.hasMany(Relation, "appEntityId"),
      isImported: this.attr(""),
      documentUrl: this.attr(""),
      // Offline data
      offlineCreated: this.boolean(false),
      data: this.attr({}),
      relatedItems: this.attr({}),
    };
  }
  get typologyTypes() {
    return TypologyType.query().findIn(this.metaDatas.typologyTypes);
  }

  static beforeCreate(model) {
    const docIdToUrlMap = this.store().state.observation.documentIdsToUrls;
    if (docIdToUrlMap[model.uuid]) {
      model.documentUrl = docIdToUrlMap[model.uuid];
    }
  }
}
