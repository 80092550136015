import { amosRootPermissions } from "@/utils/amosPermissions";

const Information = () =>
  import(
    /* webpackChunkName: "Information" */
    "@/components/amos/visits/visit/aos-item-routes/identification/Information"
  );

const Photos = () =>
  import(
    /* webpackChunkName: "Photographs" */ "@/components/amos/visits/visit/aos-item-routes/identification/Photos"
  );

const Characteristic = () =>
  import(
    /* webpackChunkName: "Characteristic" */
    "@/components/amos/visits/visit/aos-item-routes/identification/Characteristic"
  );

const DigitalModel = () =>
  import(
    /* webpackChunkName: "DigitalModel" */ "@/components/amos/visits/visit/aos-item-routes/identification/DigitalModel"
  );

const IOT = () =>
  import(
    /* webpackChunkName: "IOT" */ "@/components/amos/visits/visit/aos-item-routes/identification/IOT"
  );

const IdentificationDocumentReview = () =>
  import(
    /* webpackChunkName: "IdentificationDocumentReview" */ "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationDocumentReview"
  );

const IdentificationObservations = () =>
  import(
    /* webpackChunkName: "IdentificationObservations" */
    "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationObservations"
  );

const IdentificationActions = () =>
  import(
    /* webpackChunkName: "IdentificationActions" */ "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationActions"
  );

const IdentificationGed = () =>
  import(
    /* webpackChunkName: "IdentificationGed" */
    "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationGed"
  );

const IdentificationReports = () =>
  import(
    /* webpackChunkName: "IdentificationReports" */ "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationReports"
  );

const IdentificationDisposition = () =>
  import(
    /* webpackChunkName: "IdentificationDisposition" */ "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationDisposition"
  );

const IdentificationContributors = () =>
  import(
    /* webpackChunkName: "IdentificationContributors" */ "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationContributors"
  );

const IdentificationViewer = () =>
  import(
    /* webpackChunkName: "IdentificationViewer" */
    "@/components/amos/visits/visit/aos-item-routes/identification/IdentificationViewer"
  );

export default [
  {
    path: "",
    redirect: "identification-information",
  },
  {
    path: "information",
    name: "identification-information",
    component: Information,
    meta: {
      permissions: amosRootPermissions["identification-information"],
    },
  },
  {
    path: "photos",
    name: "identification-photos",
    component: Photos,
    meta: {
      permissions: amosRootPermissions["identification-photos"],
    },
  },
  {
    path: "characteristic",
    name: "identification-characteristic",
    component: Characteristic,
    meta: {
      permissions: amosRootPermissions["identification-characteristic"],
    },
  },
  {
    path: "digital-model",
    name: "identification-digital-model",
    component: DigitalModel,
  },
  {
    path: "iot",
    name: "identification-iot",
    component: IOT,
  },
  {
    path: "document-review",
    name: "identification-document-review",
    component: IdentificationDocumentReview,
    meta: {
      permissions: amosRootPermissions["identification-document-review"],
    },
  },
  {
    path: "observations",
    name: "identification-observations",
    component: IdentificationObservations,
    meta: {
      permissions: amosRootPermissions["identification-observations"],
    },
  },
  {
    path: "actions",
    name: "identification-actions",
    component: IdentificationActions,
    meta: {
      permissions: amosRootPermissions["identification-actions"],
    },
  },
  {
    path: "ged",
    name: "identification-ged",
    component: IdentificationGed,
    meta: {
      permissions: amosRootPermissions["identification-ged"],
    },
  },
  {
    path: "reports",
    name: "identification-reports",
    component: IdentificationReports,
  },
  {
    path: "disposition",
    name: "identification-disposition",
    component: IdentificationDisposition,
    meta: {
      permissions: amosRootPermissions["identification-disposition"],
    },
  },
  {
    path: "contributors",
    name: "identification-contributors",
    component: IdentificationContributors,
  },
  {
    path: "viewer",
    name: "identification-viewer",
    component: IdentificationViewer,
    meta: {
      permissions: amosRootPermissions["identification-viewer"],
    },
  },
];
