import DispositionType from "./DispositionType";
import SubObservation from "./SubObservation";

export default class Disposition extends SubObservation {
  static name = "Disposition";
  static entity = "dispositions";

  static fields() {
    return {
      ...super.fields(),

      dispositionTypeData: this.belongsTo(DispositionType, "dispositionType"),
      // foreign keys
      dispositionType: this.string(""),
    };
  }
}
