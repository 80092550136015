import StatementType from "./StatementType";
import QuotationValue from "./QuotationValue";
import AnalyticalAxis from "./AnalyticalAxis";
import ActionType from "./ActionType";
import { BaseModel } from "./baseModels";
export default class ObjectTypeStatement extends BaseModel {
  static name = "ObjectTypeStatement";

  static entity = "objectTypeStatements";
  static listAction = "amosAdmin/fetchObjectTypeStatements";

  static fields() {
    return {
      ...super.fields(),
      contentType: this.string(""),
      actionTypes: this.hasManyBy(ActionType, "actionTypesList"),
      actionTypesList: this.attr([""]),
      statementTypeData: this.belongsTo(StatementType, "statementType"),
      quotationValueData: this.belongsTo(QuotationValue, "quotationValue"),
      analyticalAxisData: this.belongsTo(AnalyticalAxis, "analyticalAxis"),
      isApplicableToChildren: this.boolean(),
      ordering: this.number(0),
      isArchived: this.boolean(),

      // foreign keys
      statementType: this.string(""),
      quotationValue: this.string(""),
      analyticalAxis: this.string(""),

      // content type uuid
      objectId: this.string(""),
    };
  }
}
