import {
  getRxDB,
  createCollections,
  camelToSnakeCase,
} from "@socotec.io/vuex-orm-rxdb-bridge";
import { schemas } from "@/rxdb/schemas";
import { ref } from "vue";

const apiEnv = process.env.VUE_APP_SOCIO_GRPC_API_ENV;
let initialized = false;
const db = ref(null);

export const initializeRxDB = async () => {
  if (initialized) {
    return;
  }
  const rxdbIsEnabled = process.env.VUE_APP_RXDB_ENABLE;
  if (rxdbIsEnabled === "true") {
    db.value = await getRxDB({
      useDevPlugin: apiEnv === "local",
      withCollections: false,
    });
    if (Object.keys(db.value.collections).length) {
      console.log("RxDB already defined");
    } else {
      await db.value.requestIdlePromise();
      await createCollections(db.value, schemas);
    }
  }
  initialized = true;
};

export const modelMustFetch = async (modelName, cachedModelVersion) => {
  const collectionName = camelToSnakeCase(modelName);

  const documents = await db.value[collectionName].find().exec();

  if (!documents.length) return true;

  let fetchedModelVersion = await db.value.model_version
    .find()
    .where("model")
    .equals(modelName.toLowerCase())
    .exec();

  fetchedModelVersion = fetchedModelVersion.length
    ? fetchedModelVersion[0]
    : null;

  if (!fetchedModelVersion && !cachedModelVersion) return true;

  const lastFetchedAt = new Date(cachedModelVersion.fetchedAt);
  const differenceInHours =
    (new Date().getTime() - lastFetchedAt.getTime()) / 3600000;

  return (
    fetchedModelVersion.version > cachedModelVersion.version ||
    differenceInHours > 24
  );
};

export default db;
