import { BaseAdminGroupModel } from "./baseModels";

export default class VentilationGroup extends BaseAdminGroupModel {
  static name = "VentilationGroup";

  static entity = "ventilationGroups";

  static fields() {
    return {
      ...super.fields(),
      ventilationsList: this.attr([]),
    };
  }

  get itemsList() {
    return this.ventilationsList;
  }
}
