import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetWithBuildingModel } from "./baseAssetWithBuildingModel";
export default class ZoneAsset extends BaseAssetWithBuildingModel {
  static name = "ZoneAsset";
  static entity = "zoneAssets";
  static aosModel = () => AosVuexOrmModel["AosZone"];

  static fields() {
    return {
      ...super.fields(),

      code: this.string(""),
    };
  }
}
