import { BaseModel } from "./baseModels";

export class BaseAssetModel extends BaseModel {
  static aosModel = () => null;

  static fields() {
    return {
      isProjectAsset: this.boolean(),
      aosItem: this.string(""), // foreign key
      aosItemData: this.belongsTo(this.aosModel(), "aosItem"),
      designation: this.string(""),
      path: this.string(""),
      createdOrUpdatedOffline: this.boolean(false),
      ...super.fields(),
    };
  }

  toAos() {
    return this.constructor
      .aosModel()
      .query()
      .withAllRecursive(1)
      .find(this.aosItem);
  }

  static beforeCreate(asset) {
    this.store().state.asset.aosIdToAssetIdMap[asset.aosItem] = asset.uuid;
    this.store().state.asset.pathToAssetIdMap[asset.path] = asset.uuid;
  }

  static beforeUpdate(asset) {
    this.store().state.asset.aosIdToAssetIdMap[asset.aosItem] = asset.uuid;
    this.store().state.asset.pathToAssetIdMap[asset.path] = asset.uuid;
  }
}
