import { BaseAdminModel } from "./BaseAdminModel";

export class TechnicalAdviceReferencial extends BaseAdminModel {
  static name = "TechnicalAdviceReferencial";
  static entity = "technicalAdviceReferencial";

  static fields() {
    return {
      ...super.fields(),
      isArchived: this.boolean(false),
      ordering: this.attr(0),
      nature: this.string(""),
      code: this.string(""),
      limit: this.attr(""),
    };
  }
}
