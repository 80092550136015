import { BaseModel } from "./baseModels";
import ExpenseOverTime from "./ExpenseOverTime";
import YearlyBudget from "./YearlyBudget";

export default class Scenario extends BaseModel {
  static name = "Scenario";
  static entity = "scenarios";
  static primaryKey = "uuid";
  static retrieveAction = "arbitration/retrieveScenario";

  static fields() {
    return {
      ...super.fields(),
      version: this.attr(null),
      scenarioName: this.attr(""),
      project: this.attr(""),
      isMainScenario: this.boolean(false),
      isArchived: this.boolean(false),
      budget: this.number(null).nullable(),
      opex: this.number(null).nullable(),
      capex: this.number(null).nullable(),
      defaultYearlyBudget: this.number(null).nullable(),
      lastUpdate: this.attr(),
      author: this.attr(null),
      expensesOverTime: this.hasMany(ExpenseOverTime, "scenario"),
      yearlyBudgets: this.hasMany(YearlyBudget, "scenario"),
      isInSentReport: this.boolean(false),
    };
  }
}
