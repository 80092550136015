import Vue from "vue";
import VueRouter from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import AmosChildrens from "./children/amos-children";
import Schemas from "@/components/Schemas.vue";
import store from "@/store";
import { isLoggedOrOffline, isOffline } from "./offline/isLoggedOrOffline";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const Amos = () =>
  import(/* webpackChunkName: "AmosFront" */ "@/components/Amos");
const UnauthorizedAccessOfflineMode = () =>
  import(
    /* webpackChunkName: "UnauthorizedAccessOfflineMode" */ "@/components/offline/UnauthorizedAccessOfflineMode"
  );

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Amos,
    children: AmosChildrens,
    beforeEnter: async (to, from, next) =>
      await isLoggedOrOffline(to, from, next),
  },
  {
    path: "/schemas",
    name: "schemas",
    component: Schemas,
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "/unauthorized-access-offline-mode",
    name: "unauthorized-access-offline-mode",
    component: UnauthorizedAccessOfflineMode,
    beforeEnter: isOffline,
  },
  {
    path: "*",
    redirect: "/",
  },
];

// INFO A.D.B. silences NavigationDuplicated errors.
// Solved in vue-router 4 (Vue3)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/schemas") {
    next();
  }
  const isOnline = navigator.onLine;
  if (isOnline) {
    const middleware = await vuexOidcCreateRouterMiddleware(store, "oidc");
    return middleware(to, from, next);
  }
  next();
});

export default router;
