import store from "@/store";
import dayjs from "dayjs";
import db, { initializeRxDB } from "@/rxdb/utils";
import { User } from "@/models";
import { utils } from "@socotec.io/socio-aos-component";
import { pingHealthCheck } from "@/utils/cache";
import i18n from "@/setup/i18n";

const initOfflineMode = async () => {
  const [user] = await db.value.user
    .find()
    .where("isCurrentUser")
    .eq(true)
    .exec();
  const weeks_validity =
    parseInt(process.env.VUE_APP_OFFLINE_WEEKS_VALIDITY) || 6;
  const endValidityDate = dayjs().subtract(weeks_validity, "week");
  const lastLoginDate = dayjs(user?.lastLoginDate);
  if (lastLoginDate > endValidityDate && user) {
    User.insert({ data: JSON.parse(JSON.stringify(user)) });
  } else {
    throw Error("Unauthorized access to offline mode");
  }
};

const isLoggedOrOffline = async (to, from, next) => {
  await initializeRxDB();
  // INFO/HACK - MS - 25/04/24 - if not utils.setRxDBInstance(db.value)
  // the rxdb db accessed by socio-aos-component in his compiled file (es.js) is empty of collections
  utils.setRxDBInstance(db.value);
  store.commit("SET_APP_IS_LOADING", true);
  let isOnline = navigator.onLine;
  try {
    await pingHealthCheck();
    store.commit("cacheVisitSettings/SET_OFFLINE_MODE", false);
  } catch (error) {
    console.error(error);
    store.commit("cacheVisitSettings/SET_OFFLINE_MODE", true);
    isOnline = false;
    store.dispatch(
      "notifications/showWarningNotification",
      i18n.t("offline.networkError")
    );
  }
  if (isOnline) {
    await store.dispatch("user/initializeCurrentUser");
  } else {
    try {
      await initOfflineMode();
    } catch {
      return next({ name: "unauthorized-access-offline-mode" });
    }
  }
  store.commit("SET_APP_IS_LOADING", false);
  next();
};

const isOffline = (from, to, next) => {
  if (navigator.onLine) {
    next();
  } else {
    next("/");
  }
};

export { isLoggedOrOffline, isOffline };
