import { BaseModel } from "./baseModels";

export default class AmosAosObjectType extends BaseModel {
  static name = "AmosAosObjectType";
  static entity = "amosAosObjectType";
  static listAction = "amosAos/fetchAmosAosObjectTypes";

  static fields() {
    return {
      ...super.fields(),
      label: this.attr(""),
    };
  }
}
