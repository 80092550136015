import { BaseAdminGroupModel } from "./baseModels";

export default class ReportText extends BaseAdminGroupModel {
  static name = "ReportText";
  static entity = "reportTexts";

  static fields() {
    return {
      ...super.fields(),
      text: this.attr(""),
      templateCode: this.attr(""),
      ordering: this.attr(0),
      section: this.attr(""),
      isArchived: this.boolean(false),
    };
  }
}
