import store from "@/store";

export default (to, from, next) => {
  if (
    !store.getters["projectConfig/getCurrentProjectConfig"].hasAction ||
    !store.getters["projectConfig/getCurrentProjectConfig"].hasDisposition
  ) {
    next({
      name: "aos-item-selection",
      params: {
        projectUuid: to.params.projectUuid,
      },
      query: {
        redirect: "identification-information",
      },
    });
  }
  next();
};
