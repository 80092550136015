import { BaseAdminModel } from "./BaseAdminModel";
import { missionCodesStructureMap } from "@/utils/const";
export default class StatementType extends BaseAdminModel {
  static name = "StatementType";

  static entity = "statementTypes";

  static fields() {
    return {
      ...super.fields(),
      missionsList: this.attr([]),
    };
  }

  get typeRelatedToMissions() {
    let typeRelatedToMissions = "";
    for (const key in missionCodesStructureMap) {
      if (
        this.missionsList.some((item) =>
          missionCodesStructureMap[key].includes(item)
        )
      ) {
        typeRelatedToMissions = key;
      }
    }
    return typeRelatedToMissions;
  }
}
