import { BaseAssetWithBuildingModel } from "./baseAssetWithBuildingModel";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

export default class SpaceAsset extends BaseAssetWithBuildingModel {
  static name = "SpaceAsset";
  static entity = "spaceAssets";

  static aosModel = () => AosVuexOrmModel["AosSpace"];

  static fields() {
    return {
      ...super.fields(),

      code: this.string(""),
    };
  }
}
