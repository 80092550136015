import { BaseAdminGroupModel } from "./baseModels";

export default class ProjectionGroup extends BaseAdminGroupModel {
  static name = "ProjectionGroup";

  static entity = "projectionGroups";
  static listAction = "amosAdmin/fetchProjectionGroups";
  static retrieveAction = "amosAdmin/fetchProjectionGroups";

  static fields() {
    return {
      ...super.fields(),
      projectionsList: this.attr([]),
    };
  }

  get itemsList() {
    return this.projectionsList;
  }
}
